.home-body {
    height: 100vh;
    width: 100vw;
    box-sizing: inherit;
    padding: 0 33vw 0 33vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &.mount {
        animation: fade-in 1s ease-in forwards;
        opacity: 0;
        .spacer {
            animation-name: slide-in;
            animation-duration: 1s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            -webkit-animation-name: slide-in;
            -webkit-animation-duration: 1s;
            -webkit-animation-timing-function: ease-out;
            -webkit-animation-fill-mode: forwards;
        }
    }

    &.unmount {
        animation: fade-out 0.3s forwards;
        .spacer {
            animation-name: slide-out;
            animation-duration: 1s;
            animation-timing-function: ease-in;
            animation-fill-mode: forwards;
            -webkit-animation-name: slide-out;
            -webkit-animation-duration: 1s;
            -webkit-animation-timing-function: ease-in;
            -webkit-animation-fill-mode: forwards;
        }
    }
}

@keyframes fade-in {
    100% { opacity: 1; }
    50% { opacity: 0; }
}

@keyframes fade-out {
    100% { opacity: 0; }
}

@keyframes slide-in {
    0% { flex-grow: 1; }
    100% { flex-grow: 0; }
}

@-webkit-keyframes slide-in {
    0% { flex-grow: 1; }
    100% { flex-grow: 0; }
}

@keyframes slide-out {
    0% { flex-grow: 0; }
    100% { flex-grow: 1; }
}

@-webkit-keyframes slide-out {
    0% { flex-grow: 0; }
    100% { flex-grow: 1; }
}