.leisure {
    display: flex;
    flex-direction: row;
    box-sizing: inherit;
    height: calc(100% - 65px);
    color: #F40058;
    opacity: 0;
    animation: fade-in 0.5s forwards;
    .btn {
        width: 40px;
        height: 40px;
    }
    
}

@keyframes fade-in {
    100% { opacity: 1; }
}