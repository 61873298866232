.navbar {
    width: 100%;
    height: 65px;
    background: #43BEE5;
    display: flex;
    flex-direction: row;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    .tabs {
        display: flex;
        flex-direction: row;
        box-sizing: content-box;
        height: 100%;
        align-items: center;
        width: 100%;
        .tab {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px 0 20px;
            height: 100%;
            box-sizing: border-box;
            border-top-left-radius: 20px 20px;
            border-top-right-radius: 20px 20px;
            font-weight: 600;
            cursor: pointer;
            &.selected {
                background: #161B40;
                color: #F40058;
            }
            &.unselected {
                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .options {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        .user-icon {
            height: inherit;
            padding: 0 40px 0 40px;
            box-sizing: border-box;
            border-top-left-radius: 20px 20px;
            border-top-right-radius: 20px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.selected {
                background: #161B40;
                color: #F40058;;
            }
            &.unselected {
                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}