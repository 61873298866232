.column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    max-height: inherit;
    .leisure-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 40px 40px 20px 40px;
        .title {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .text {
                font-size: 40px;
                font-weight: 900;
            }
        }
        .total-time {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .time {
                font-size: 50px;
                font-weight: 900;
                color: #EFA500;
            }
            .btn-group {
                display: flex;
                flex-direction: row;
                .btn {
                    margin-left: 20px;
                }
            }
        }
    }
    .body {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}