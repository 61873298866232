.App {
    text-align: center;
    height: 100vh;
    background: #161B40;
    box-sizing: border-box;
    font-family: 'Andale Mono', sans-serif;
    cursor: default;
    h1 {
        color: #F40058;
        font-size: 120px;
    }
    .app-body {
        display: flex;
        flex-direction: column;
        box-sizing: inherit;
        height: inherit;
    }
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: none;
    outline: none;
    user-select: none;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 4px 5px 10px rgba($color: #000000, $alpha: 0.1);
    border-radius: 50%;
    height: 60px;
    width: 60px;
    cursor: pointer;

    &.home {
        background: #43BEE5;
        transition: width 0.3s;
        font-weight: 600;
        font-size: 30px;
        
        &:hover {
            border-radius: 50px;
            width: 30%;
        }
    }

    &.function {
        transition: 0.3s;
        background: white;

        &:hover {
            background: #43BEE5;
        }
    }

    &.disabled {
        pointer-events: none;
        background: white;
        opacity: 0.2;
    }

    &:active {
        box-shadow: 0px 0px 0px #666;
        transform: translateX(3px) translateY(4px);
    }
}


//#C7DAE4 box coloring